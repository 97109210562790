import React, { useState } from "react";
import { FaBars, FaTimes, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { Link } from "react-scroll";
import { useScrollPosition } from "../hooks/useScrollPosition";

const Navbar = ({ activeScreen }) => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const activeClass = (name) =>
    name === activeScreen
      ? "from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-r bg-clip-text text-transparent"
      : "";
  const scrollPosition = useScrollPosition();
  return (
    <div
      className={`lg:flex fixed flex-col p-4 text-left z-20 items-start w-full md:w-1/6 hover:cursor-pointer md:backdrop-blur-0 md:backdrop-hue-rotate-0 ${
        scrollPosition ? "backdrop-blur-sm backdrop-hue-rotate-90" : ""
      }`}
    >
      <Link to="home" smooth={true} duration={500}>
        <h1
          className={`font-bold text-3xl text-cyan-700 mb-2 mt-1 ${activeClass(
            "home"
          )}`}
        >
          MOr
        </h1>
      </Link>

      <ul className="h-0 md:h-auto overflow-hidden">
        <li
          className={`text-cyan-700 hover:text-teal-500 ml-[-10px] mb-10px text-lg ${activeClass(
            "about"
          )}`}
        >
          <Link to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        {/* <li
          className={`text-cyan-700 hover:text-teal-500 ml-[-10px] mb-10px text-lg ${activeClass(
            "skills"
          )}`}
        >
          <Link to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li> */}
        <li
          className={`text-cyan-700 hover:text-teal-500 ml-[-10px] mb-10px text-lg ${activeClass(
            "projects"
          )}`}
        >
          <Link to="work" smooth={true} duration={500}>
            Roles
          </Link>
        </li>
        <li className="text-cyan-700 hover:text-teal-500 ml-[-10px] mb-10px text-lg ">
          <a
            className="flex mt-4"
            href="https://www.linkedin.com/in/mari%C3%A1n-orsz%C3%A1gh-47997b146/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </li>
        <li className="text-cyan-700 hover:text-teal-500 ml-[-10px] mb-10px text-lg ">
          <a className="flex" href="mailto:orszaghm5@gmail.com">
            Email Me
          </a>
        </li>
      </ul>
      {/* Haburguesa */}
      <div
        onClick={handleClick}
        className={`md:hidden z-50 text-3xl text-cyan-700 hover:text-teal-500 absolute right-6 top-6`}
      >
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      <div
        className={`${
          nav ? "h-screen" : "h-0 overflow-hidden"
        } md:hidden absolute top-0 left-0 w-full via-yellow-100 to-yellow-100 from-teal-400  bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] text-cyan-700 transition-height duration-300 ease-in-out`}
      >
        <div className="inset-0 absolute bg-[url('assets/grain.svg')] bg-repeat bg-sm grain opacity-10 z-0"></div>

        <div className="relative w-full h-5/6 z-10 pt-20 pl-5">
          <ul className="flex-col flex justify-center items-left  w-full">
            <li className="py-6 text-6xl">
              {" "}
              <Link
                onClick={handleClick}
                to="about"
                smooth={true}
                duration={500}
              >
                About
              </Link>
            </li>
            {/* <li className="py-6 text-6xl">
              {" "}
              <Link
                onClick={handleClick}
                to="skills"
                smooth={true}
                duration={500}
              >
                Skills
              </Link>
            </li> */}
            <li className="py-6 text-6xl">
              {" "}
              <Link
                onClick={handleClick}
                to="work"
                smooth={true}
                duration={500}
              >
                Roles
              </Link>
            </li>
          </ul>
        </div>

        <div className="relative z-10">
          <ul className="flex justify-around w-full">
            <li className="py-6 text-4xl">
              <a
                className="flex w-full text-cyan-700"
                href="https://www.linkedin.com/in/mari%C3%A1n-orsz%C3%A1gh-47997b146/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin size={40} />
              </a>
            </li>

            <li className="py-6 text-4xl ">
              <a
                className="flex w-full text-cyan-700"
                href="mailto:orszaghm5@gmail.com"
              >
                <FaEnvelope size={40} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
