import "./App.css";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Home from "./components/Home";
import { useRef, useState, useMemo, useEffect } from "react";

function App() {
  const [intersectingName, setIntersectingName] = useState(undefined);

  useEffect(() => {
    console.log(intersectingName);
  }, [intersectingName]);

  const refHome = useRef(null);
  const refAbout = useRef(null);
  // const refSkills = useRef(null);
  const refProjects = useRef(null);
  useIsInViewport(refHome, "home", setIntersectingName);
  useIsInViewport(refAbout, "about", setIntersectingName);
  // useIsInViewport(refSkills, "skills", setIntersectingName);
  useIsInViewport(refProjects, "projects", setIntersectingName);

  return (
    <div className="App">
      <div className="bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-yellow-400 via-rose-200 to-yellow-200">
        <Navbar activeScreen={intersectingName} />

        <div ref={refHome}>
          <Home />
        </div>
        <div ref={refAbout}>
          <About />
        </div>
        {/* <About /> */}
        {/* <div ref={refSkills}>
          <Skills />
        </div> */}
        <div ref={refProjects}>
          <Projects />
        </div>
        {/* <Contact /> */}
        <footer class="text-center">
          <div class="p-8 text-center text-teal-700">
            © {new Date().getFullYear()} Marián Országh
          </div>
        </footer>
      </div>
    </div>
  );
}

function useIsInViewport(ref, name, setIntersecting) {
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIntersecting(name);
          }
        },
        { threshold: 0.51 }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);
}

export default App;
