import React, { useEffect, useState } from "react";
import { StrapiAPI } from "../services/Strapi";
import { LoadingSpinner } from "./utils/LoadingSpinner";
import { getImageUrl } from "../services/configs/axiosUtils";
import "./Projects.css";
import Timeline from "./Timeline";

const Project = ({ name, description, url, thumbnail }) => {
  return (
    <div
      style={{ backgroundImage: `url(${getImageUrl(thumbnail)})` }}
      className="shadow-lg shadow-[#939393] group container rounded-md flex justify-center items-center mx-auto content-div"
    >
      <div className="opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col p-5">
        <span className=" text-lg font-bold text-white tracking-wider">
          {name}
        </span>
        <p className="text-center">{description}</p>
        <div className="pt-8 text-center">
          <a href={url} target="_blank" rel="noreferrer">
            <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg hover:bg-gray-200">
              Visit
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

const Projects = () => {
  const [loading, setLoading] = useState(true);
  const [nameToProject, setNameToProject] = useState(undefined);

  // useEffect(() => {
  //   setLoading(true);
  //   StrapiAPI.getProjects()
  //     .then((projects) => {
  //       setNameToProject(
  //         new Map(
  //           projects.map((proj) => [proj.attributes.name, proj.attributes])
  //         )
  //       );
  //       Array.from(nameToProject);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <div name="work" className="w-full min-h-screen text-cyan-700 relative">
      <div className="absolute bg-[url('assets/grain.svg')] w-full min-h-full bg-repeat bg-auto grain opacity-30 z-0"></div>
      <div className="relative max-w-[1000px] mx-auto p-4 flex flex-col justify-center h-full z-1 w-5/6">
        <div className="w-full flex justify-center items-center flex-col pt-10">
          <h1 className="text-5xl font-bold inline text-cyan-700">Roles</h1>
          <p className="py-6 text-2xl">
            My current and past professional roles
          </p>
        </div>
        <div className="flex justify-center items-center pb-20">
          <div className="absolute bg-[url('assets/grain.svg')] w-full  bg-repeat bg-auto grain opacity-40 z-0"></div>
          <Timeline />
          {/* {loading || !nameToProject ? <LoadingSpinner /> : <Timeline />} */}
        </div>
      </div>
    </div>
  );
};
export default Projects;
