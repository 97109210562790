import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";


export const StrapiAPI = {
  getProjects: async (cancel = false) =>  {
    const resp = await api.request({
      url: `/projects?populate=*`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });
    return resp.data.data;
  }
}

const cancelApiObject = defineCancelApiObject(StrapiAPI);
