import "./Timeline.css";
import { DiAngularSimple } from "react-icons/di";
import {
  SiArgo,
  SiFastapi,
  SiLeaflet,
  SiRedhatopenshift,
} from "react-icons/si";

import {
  BiLogoTypescript,
  BiLogoKubernetes,
  BiLogoPostgresql,
  BiLogoPython,
  BiLogoGitlab,
  BiLogoMongodb,
  BiLogoDocker,
  BiLogoAngular,
} from "react-icons/bi";

const Timeline = () => {
  return (
    <div className="container px-4 py-12 mx-auto">
      <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9 text-left">
        <div className="col-span-12 space-y-12 relative sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-cyan-700">
          <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-cyan-600">
            <h3 className="text-3xl font-semibold inline">
              Senior CloudOps Engineer | Wrike{" "}
            </h3>
            <h3>
              <BiLogoTypescript />
              <DiAngularSimple />
              <BiLogoPython />
              <SiFastapi />
              <BiLogoPostgresql />
              <BiLogoGitlab />
              <BiLogoKubernetes />
              <SiArgo />
            </h3>
            <time className="text-s  uppercase dark:text-gray-400 mt-2">
              2021 - 2024
            </time>
            <p className="mt-3 text-s">
              As a CI/CD Platform Engineer, I specialize in Python, FastAPI, and
              Angular with RxJS, and also PostgresQL driving the development,
              testing, and maintenance of our internal CI/CD platform. I
              automate microservices creation and deployment, optimize
              operational efficiency through migration to ArgoCD, and contribute
              strategic thinking to project scoping and design. Meticulously
              documenting internal processes, I navigate the dynamic landscape
              of CI/CD infrastructure and software deployment.
            </p>
          </div>
          <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-cyan-600">
            <h3 className="text-3xl font-semibold inline">
              Software Engineer | Red Hat{" "}
            </h3>
            <h3>
              <BiLogoPython />
              <BiLogoTypescript />
              <DiAngularSimple />
              {/* <SiFastapi /> */}
              <BiLogoMongodb />
              <BiLogoGitlab />
              <BiLogoKubernetes />
              <SiRedhatopenshift />
            </h3>
            <time className="text-s  uppercase dark:text-gray-400 mt-2">
              2017 - 2019
            </time>
            <p className="mt-3 text-s">
              In my four years as a Software Engineer at Red Hat, I contributed
              to the development and maintenance of a web-based container
              metadata management application. Engaging in both frontend and
              backend work using Python, Flask, and Angular with RxJS and NgRx,
              I designed and implemented an automated end-to-end test framework
              with Selenium. I also handled the development and upkeep of a REST
              API for container metadata, implemented CI/CD via GitLab
              pipelines, and orchestrated the containerization and deployment of
              services into OpenShift. Beyond technical contributions, I played
              a key role in project scoping, design, and the onboarding of new
              team members.
            </p>
          </div>
          <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-cyan-600">
            <h3 className="text-3xl font-semibold inline">
              Front End Developer | World from Space{" "}
            </h3>
            <h3>
              <BiLogoTypescript />
              <BiLogoAngular />
              <SiLeaflet />
            </h3>
            <time className="text-s  uppercase dark:text-gray-400 mt-2">
              2019
            </time>
            <p className="mt-3 text-s">
              I designed and developed a web map application with visualizations
              of satellite imagery, including temperature, greenery, and
              droughts. Implemented using Angular and Typescript.
            </p>
          </div>
          <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-cyan-600">
            <h3 className="text-3xl font-semibold inline">Intern | Red Hat </h3>
            <h3>
              <BiLogoPython />
              {/* <SiFastapi /> */}
              <BiLogoGitlab />
              <BiLogoKubernetes />
              <SiRedhatopenshift />
              <BiLogoDocker />
            </h3>
            <time className="text-s  uppercase dark:text-gray-400 mt-2">
              2017 - 2019
            </time>
            <p className="mt-3 text-s">
              During my internship, I developed and maintained various
              automation microservices in Python. Notably, I designed,
              developed, and maintained a service to gather Merge (Pull) request
              statistics from Git services (GitHub, GitLab, Pagure, Gerrit).
              This service published statistics to an ELK stack, offering
              insightful visualizations for managers and team leads.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
