import React from "react";

const Home = () => {
  return (
    <div name="home" id="home" className="w-full min-h-screen text-gray-200 ">
      <div className="inset-0 flex flex-1 absolute bg-[url('assets/grain.svg')] bg-repeat bg-auto grain opacity-30"></div>
      <div className="flex flex-col justify-center items-center w-full min-h-screen">
        <div className="py-16 rounded-lg flex flex-col justify-center items-center w-4/6 z-10 opacity-95">
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
            <div className="sm:text-right text-5xl lg:text-6xl font-bold bg-gray- p-3 text-cyan-700 rounded-l-xl backdrop-blur-xl">
              <h1>Hello, I&nbsp;m Marian.</h1>
            </div>
            <div className="text-left pr-10 bg-cyan-700 rounded-xl  p-4 text-lg align-middle">
              <span className="align-middle">
                A software enginner experienced with work both on the front and
                the backend of web applications and microservices.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
