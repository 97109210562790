import axios from "axios"

const STRAPI_TOKEN = "b0a60951d7f1167be9865cfc906af6b168e81db1435aa45bacd6ba0c5ec9bfcceeb1445b3032ee89f907656ace15e32cb6126f4c6ca7866bbd358e2b64c282c7685ee567949b3f1709e7f75efe6f9498676e8bb092959dc21d9ed7d5db4e39d4bd1fb72c313386331a908a7b104d54a0ac16f34eaaa417764cc0b1e96b2bea43"

export const host = "http://localhost:1337";

export const api = axios.create({
  withCredentials: true,
  baseURL: `${host}/api`,
  headers: {
    Authorization: `Bearer ${STRAPI_TOKEN}`
  }
})

const errorHandler = (error) => {
  const statusCode = error.response?.status

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
}

const debugHandler = (resp) => {
  console.debug(resp);
  return resp;
}

api.interceptors.response.use((resp) => {
  return debugHandler(resp);
}, (error) => {
  return errorHandler(error)
})