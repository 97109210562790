import React from "react";

const About = () => {
  return (
    <div name="about" id="about" className="w-full min-h-screen text-cyan-700 ">
      <div className="absolute bg-[url('assets/grain.svg')] w-full min-h-screen bg-repeat bg-auto grain opacity-30 z-0"></div>
      <div className="absolute bg-[url('assets/stroke.svg')] md:w-full w-0 h-full bg-contain bg-bottom bg-no-repeat"></div>
      <div className="flex flex-col justify-center items-center w-full min-h-full align-top">
        <div className="py-16 rounded-lg flex flex-col justify-center items-start w-4/6 z-10 opacity-95">
          <div className="max-w-[1000px] w-full grid lg:grid-cols-2 gap-8 px-4 text-lg">
            <div>
              <p>
                As a seasoned Software Engineer, I bring expertise in Python,
                Typescript Angular, RxJS, NgRx, React.js and SQL. My skills
                include the development and maintenance of RESTful automation
                microservices on the backend and dynamic web applications on the
                frontend.
              </p>
            </div>
            <div className="sm:h-4"></div>
            <div>
              <p>
                Skilled in project scoping, design, and well-versed in CI/CD and
                DevOps practices, I bring a versatile skill set, adaptable to a
                variety of projects and positions. My experience ensures a
                comprehensive approach, delivering effective solutions and
                contributing holistically to project success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
